<template>
  <div class="d-flex align-center py-4" :class="containerClasses">
    <div class="column ml-2">
      <p class="ma-0"><strong>Tipo:</strong></p>
      <p class="ma-0">{{ request.type | type }}</p>
    </div>
    <div class="column ml-2">
      <p class="ma-0"><strong>Componente:</strong></p>
      <p class="ma-0">{{ request.component }}</p>
    </div>
    <div class="column ml-2">
      <p class="ma-0"><strong>Marca:</strong></p>
      <p class="ma-0">{{ request.brand }}</p>
    </div>
    <div class="column ml-2">
      <p class="ma-0"><strong>Modelo:</strong></p>
      <p class="ma-0">{{ request.model }}</p>
    </div>
    <div class="column ml-2">
      <p class="ma-0"><strong>Quantidade:</strong></p>
      <p class="ma-0">
        {{ request.amount }}
        {{ pluralize(request.amount, "unidade", "unidades") }}
      </p>
    </div>
    <div class="actions ml-2 px-2 d-flex flex-wrap justify-end">
      <v-btn
        color="secondary-color-1"
        :block="$isMobile"
        class="ma-0 mt-1 mx-2 next-step-button white--text"
        @click="prepareAcceptRequest"
        >Aceitar</v-btn
      >
      <v-btn
        class="mt-1 mx-2 cancel-button black-3--text"
        color="#ffffff"
        :block="$isMobile"
        @click="prepareRefuseRequest"
        >Recusar</v-btn
      >
    </div>
    <accept-request-dialog
      :state="acceptRequestDialogState"
      :fnConfirm="handleAcceptConfirm"
    />
    <refuse-request-dialog
      :state="refuseRequestDialogState"
      :fnConfirm="handleRefuseConfirm"
    />
  </div>
</template>

<script>
  import AcceptRequestDialog from "@/modules/stock/parts-inventory/view/components/AcceptRequestDialog";
  import RefuseRequestDialog from "@/modules/stock/parts-inventory/view/components/RefuseRequestDialog";

  import { inventoryMoveType } from "@/modules/stock/parts-inventory/enums/inventoryMoveType";
  import { inventoryMoveSituation } from "@/modules/stock/parts-inventory/enums/inventoryMoveSituation";

  import { mapActions } from "vuex";

  export default {
    name: "RequestItem",

    props: {
      request: {
        type: Object,
        required: true
      }
    },

    filters: {
      type(value) {
        return Object.values(inventoryMoveType).find(type => type.id === value)
          .description;
      }
    },

    components: {
      AcceptRequestDialog,
      RefuseRequestDialog
    },

    data: () => ({
      acceptRequestDialogState: {
        dialog: false
      },

      refuseRequestDialogState: {
        dialog: false
      }
    }),

    computed: {
      containerClasses() {
        return {
          "flex-column": this.$isMobile,
          "align-start": this.$isMobile
        };
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/partInventory", ["patchStockRequest"]),

      prepareAcceptRequest() {
        this.acceptRequestDialogState.dialog = true;
      },

      prepareRefuseRequest() {
        this.refuseRequestDialogState.dialog = true;
      },

      async handleAcceptConfirm() {
        await this.callPatchStockRequest({
          id: this.request.id,
          body: {
            situation: {
              id: inventoryMoveSituation.APROVED.id
            }
          }
        });
      },

      async handleRefuseConfirm() {
        await this.callPatchStockRequest({
          id: this.request.id,
          body: {
            situation: {
              id: inventoryMoveSituation.REFUSED.id
            }
          }
        });
      },

      async callPatchStockRequest(payload) {
        try {
          const response = await this.patchStockRequest(payload);

          this.toggleSnackbar({
            text: response?.message ?? "Registro atualizado com sucesso",
            type: "success"
          });

          this.$emit("updated");
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .column {
    flex: 1;
  }
  .actions {
    flex: 3;
  }
</style>
