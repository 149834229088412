<template>
  <div class="table-page pt-7">
    <div class="table-page d-flex flex-column justify-space-between">
      <div>
        <template v-if="showFilters">
          <div class="px-5 d-flex justify-end">
            <v-col cols="12" md="4">
              <v-text-field
                @input="setFilterDebounced('search', $event)"
                solo
                flat
                full-width
                color="secondary-color-1"
                background-color="white-1"
                placeholder="Buscar (Ex: Componente)"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
          </div>
          <v-divider></v-divider>
        </template>
        <template v-if="items.length">
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="tableInfo.itemsPerPage"
            hide-default-footer
          />
        </template>
        <div class="pt-10" v-else>
          <inventory-not-found :title="dataNotFound" />
        </div>
      </div>
      <div v-if="items.length">
        <pagination
          :currentPage="tableInfo.currentPage"
          :totalPages="tableInfo.totalPages"
          :itemsPerPage="tableInfo.itemsPerPage"
          @currentPageChanged="handleCurrentPageChange($event)"
          @itemsPerPageChanged="handleItemsPerPageChange($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Pagination from "@/modules/core/views/components/Pagination";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import InventoryNotFound from "@/modules/stock/parts-inventory/view/components/InventoryNotFound";

  import { mapActions } from "vuex";

  export default {
    name: "ListInventory",

    components: {
      Pagination,
      InventoryNotFound
    },

    mixins: [TableHandler],

    data: () => ({
      items: [],

      headers: [
        { text: "Componente", value: "component" },
        { text: "Marca", value: "brand" },
        { text: "Modelo", value: "model" },
        { text: "Quantidade", value: "amount" }
      ]
    }),

    mounted() {
      this.fetchData();
    },

    computed: {
      showFilters() {
        if (this.items.length) {
          return true;
        }
        return !this.items.length && !this.filterPristine;
      },
      dataNotFound() {
        return [
          "Você ainda não possui nenhum",
          "componente do Banco de Peças",
          "Aguarde!"
        ];
      }
    },

    methods: {
      ...mapActions("stock/partInventory", ["getPartsFromInventory"]),

      async fetchData() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getPartsFromInventory(payload);

          this.setTableInfo(meta);
          this.items = this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatData(data) {
        return data.map(item => ({
          component: item.part?.partType?.description || "não informado",
          brand: item.part.brand.description,
          model: item.part.model,
          amount: item.amount
        }));
      },

      updateData() {
        this.fetchData();
      }
    }
  };
</script>

<style></style>
