<template>
  <v-container>
    <title-header :title="$route.meta.title" />

    <v-tabs
      active-class="tab-active"
      hide-slider
      background-color="transparent"
      v-model="tab"
    >
      <v-tab
        class="text-capitalize primary-color-2--text"
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <list-requests
            :key="`list-requests-${updateKey}`"
            @updated="handleUpdate"
          />
        </v-tab-item>
        <v-tab-item>
          <list-inventory :key="`list-inventory-${updateKey}`" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import ListRequests from "@/modules/stock/parts-inventory/view/components/ListRequests";
  import ListInventory from "@/modules/stock/parts-inventory/view/components/ListInventory";

  export default {
    name: "PartsInventory",

    components: {
      TitleHeader,
      ListRequests,
      ListInventory
    },

    data: () => ({
      tab: null,

      updateKey: 1,

      items: ["Requisições", "Estoque"]
    }),

    methods: {
      handleUpdate() {
        this.updateKey++;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  .tab-active {
    background: white !important;
  }

  .v-tab {
    border-radius: 6px 6px 0 0;
    &[aria-selected="false"] {
      background: rgba(255, 255, 255, 0.12);
      color: white !important;
    }
  }

  .v-tabs-items {
    border-radius: 0 6px 6px 6px;
  }
</style>
