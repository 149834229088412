export const inventoryMoveSituation = {
  PENDING: {
    id: 1,
    description: "Pendente"
  },
  APROVED: {
    id: 2,
    description: "Aprovado"
  },
  REFUSED: {
    id: 3,
    description: "Recusado"
  }
};
