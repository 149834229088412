<template>
  <div class="table-page">
    <div
      class="table-page d-flex flex-column justify-space-between"
      v-if="items.length"
    >
      <div
        class="fill-height d-flex flex-column justify-start"
        v-if="items.length"
      >
        <div v-for="(item, index) of items" :key="`request-${index}`">
          <request-item :request="item" @updated="$emit('updated')" />
          <v-divider v-if="index < items.length"></v-divider>
        </div>
      </div>
      <pagination
        :currentPage="tableInfo.currentPage"
        :totalPages="tableInfo.totalPages"
        :itemsPerPage="tableInfo.itemsPerPage"
        @currentPageChanged="handleCurrentPageChange($event)"
        @itemsPerPageChanged="handleItemsPerPageChange($event)"
      />
    </div>
    <div class="pt-10" v-else>
      <request-not-found :title="dataNotFound" />
    </div>
  </div>
</template>

<script>
  import RequestNotFound from "@/modules/stock/parts-inventory/view/components/RequestNotFound";
  import Pagination from "@/modules/core/views/components/Pagination";
  import RequestItem from "@/modules/stock/parts-inventory/view/components/RequestItem";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  import { mapActions } from "vuex";

  export default {
    name: "ListRequests",

    components: {
      Pagination,
      RequestNotFound,
      RequestItem
    },

    mixins: [TableHandler],

    data: () => ({
      items: []
    }),

    mounted() {
      this.fetchData();
    },

    computed: {
      dataNotFound() {
        return [
          "Você ainda não possui nenhuma",
          "requisição para responder",
          "Aguarde!"
        ];
      }
    },

    methods: {
      ...mapActions("stock/partInventory", ["getStockRequests"]),

      async fetchData() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getStockRequests(payload);

          this.setTableInfo(meta);
          this.items = this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatData(data) {
        return data.map(item => ({
          id: item.id,
          type: item.type,
          component: item.stock?.part?.partType?.description || "não informado",
          brand: item.stock?.part?.brand?.description || "não informado",
          model: item.stock?.part?.model || "não informado",
          amount: item.amount
        }));
      },

      updateData() {
        this.fetchData();
      }
    }
  };
</script>

<style></style>
